.vision {
	padding: rem(65px 0);
	height: rem(560px);
	font-size: rem(30px);
	line-height: 1.6;
	position: relative;
	overflow: hidden;
	color:cl(white);
	text-align: center;
	background:cl(primary) url(../img/vision-bg-1x.png) repeat-x 50% 100%;
	background-size: cover;
	@media screen and (min-device-pixel-ratio: 2),
			screen and (min-resolution: 192dpi),
			screen and (min-resolution: 2dppx) {background-image: url(../img/vision-bg-2x.png);background-size: 100% auto;
		background-position: center bottom;
		background-repeat: no-repeat;}
	@include media-breakpoint-down(xs) {
		font-size: rem(20px);
		line-height: 1.6;
		height: auto;
		padding: 3rem 0 8rem;
	}
	&--light {
		color:cl(text);
		background-image: url(../img/vision-bg-light-2x.png);
		background-size: 100% auto;
		background-position: center bottom;
		background-repeat: no-repeat;
		@media screen and (min-device-pixel-ratio: 2),
		screen and (min-resolution: 192dpi),
		screen and (min-resolution: 2dppx) {background-image: url(../img/vision-bg-light-2x.png);background-size: 100% auto;
			background-position: center bottom;
			background-repeat: no-repeat;}
		h2 {
			color:cl(primary);
		}
	}
	h2 {
		font-size: rem(40px);
		text-transform: uppercase;
		font-weight: 700;
		margin: rem(0 0 30px);
	}
	&__slogan {
		position: absolute;
		bottom: rem(-37px);
		left:0;
		width:100%;
		color:cl(white);
		text-align: center;
		margin: 0;
		font-size: rem(200px);
		line-height: 1;
		font-family: $fontTitle;
		white-space: nowrap;
		text-transform: uppercase;
		@include media-breakpoint-down(lg) {
			font-size: rem(150px);
		}
		@include media-breakpoint-down(md) {
			font-size: rem(130px);
		}
		@include media-breakpoint-down(xs) {
			font-size: rem(60px);
			bottom: -0.7rem;
		}
		span {
			background: url(../img/svg/SomersetOhio_Logo-leaf-white.svg) no-repeat 50% 20%;
			background-size: contain;
		}
	}
}