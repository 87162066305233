.footer {
  background: cl(white);
  padding: rem(50px 0 0);
  color:cl(text);
  font-size: rem(15px);
  line-height:calc(25/15);
  p {
    margin: 0;
  }
  a {
    color:cl(text);
    &:hover {
      text-decoration: none;
      color:cl(secondary);
    }
  }
  address{
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  &__title {
    font-size: rem(15px);
    margin: rem(0 0 10px);
    font-weight: 700;
    text-transform: uppercase;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  &__bottom {
    background: #0a264a;
    padding: rem(10px 0);
    @include media-breakpoint-down(md) {
      padding: rem(10px 0 100px 0);
    }
  }
  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:rem(10px);
    margin-top: 2rem;
    @include media-breakpoint-down(lg) {
      justify-content: center;
      margin-bottom: rem(30px);
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(42px);
      border-width:1px;
      border-style: solid;
      transition: transform .3s ease;
      &:hover {
        text-decoration: none;
        transform:scale(1.1);
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  &__menu {
    list-style: none;
  }
  &__copy {
    display: block;
    color:cl(white);
    font-size: rem(14px);
  }
  &__mail {
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    // @include circle(35px);
    // border:1px solid cl(primary);
    // background: cl(primary);
    // transition: background .3s ease;
    padding: rem(0 5px);
    color:cl(primary)!important;
    &:hover {
      text-decoration: none;
      color:cl(secondary)!important;
    }
    i {
      font-size: rem(15px);
    }
  }
  img{
    max-width: 100%;
  }
}


.apply-mobile-buttom{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  display: none;
  padding: 0.5rem 0.5rem;
  background-color: cl(white);
  z-index: 10;
  text-align: center;
  box-shadow: rem(5px 0 10px rgba(0,0,0,0.2));
  @include media-breakpoint-down(md) {
    display: block;

  }
  .btn{
    min-width: rem(250px);
    @include media-breakpoint-down(xs) {
      width: 100%;
      min-width: unset;
    }
  }
}


.logo {
  &--footer {
    display: block;
    width:rem(255px);
    max-width:100%;
    margin: rem(0 0 36px);
    @include media-breakpoint-down(lg) {
      margin: 0 auto 2rem;
    }
  }
  &--cognia {
    display: inline-block;
    vertical-align: top;
    width:rem(110px);
    max-width:100%;
    @include media-breakpoint-down(xs) {
      width:rem(90px);
    }
  }
  &--somerset {
    display: inline-block;
    vertical-align: top;
    width:rem(150px);
    max-width:100%;
    @include media-breakpoint-down(lg) {
      width:rem(100px);
      display: block;
      margin: 0 auto 2rem;
    }
  }
  &--fortify {
    display: inline-block;
    vertical-align: top;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}