.contact {
  &__bottom {
    padding: rem(65px 0);
    overflow: hidden;
    position: relative;
    background: cl(primary);
    color:cl(white);
    font-size: rem(24px);
    line-height: 1.4;
    .container {
      position: relative;
      z-index: 2;
    }
    h3 {
      font-size: rem(40px);
      font-weight: 700;
      text-transform: uppercase;
      margin: rem(0 0 30px);
    }
    p {
      margin: rem(0 0 30px);
    }
  }
  &__decor {
    @extend .pos-centerY;
    width:rem(880px);
    height:rem(880px);
    right:rem(50px);
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(lg) {
      right:-15.875rem;
    }
    @include media-breakpoint-down(xs) {
      right:-13rem;
      margin-top: 6rem;
      width: 40rem;
      height: 40rem;
    }
  }
  &__rays {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: url(../img/rays.png) no-repeat 50% 50%;
    background-size: contain;
    animation: round 60s infinite linear;
    @media screen and (min-device-pixel-ratio: 2),
            screen and (min-resolution: 192dpi),
            screen and (min-resolution: 2dppx) {background-image: url(../img/rays-2x.png);}
  }
  &__book {
    width:rem(210px);
    height: auto;
    margin-bottom: rem(20px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width: 8rem;
      opacity: .3;
    }
  }
}