.static {
  &--page {
    padding: rem(55px 0 200px);
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-bottom: 7rem;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 4rem;
    }
  }
  &.award--page {
    padding: rem(55px 0 200px);
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-bottom: 7rem;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 4rem;
    }
    .date-info{
      font-size: rem(16px);
      margin-bottom: rem(20px);
    }
    .text-small{
      font-size: rem(16px);
      margin-bottom: rem(20px);
    }
  }
  &__article {
    font-size: rem(18px);
    line-height: 1.5;
    color:cl(text);
    overflow: hidden;
    p {
      margin: rem(0 0 25px);
    }
    h2 {
      font-size: rem(30px);
      margin: rem(0 0 25px);
      font-weight: 700;
      color:cl(primary);
    }
    h3 {
      font-size: rem(24px);
      margin: rem(0 0 25px);
      font-weight: 700;
      color:cl(primary);
    }
    h4 {
      font-size: rem(22px);
      margin: rem(0 0 25px);
      font-weight: 700;
      color:cl(primary);
    }
    h5 {
      font-size: rem(20px);
      margin: rem(0 0 25px);
      font-weight: 700;
      color:cl(primary);
    }
    h6 {
      font-size: rem(18px);
      margin: rem(0 0 25px);
      font-weight: 700;
      color:cl(primary);
    }
    ol{
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
    ul {
      padding: 0;
      li {
        padding: rem(0 0 0 46px);
        margin: rem(0 0 5px);
        position: relative;
        list-style: none;
        &:before {
          content: "";
          position: absolute;
          left:rem(22px);
          top:rem(10px);
          width:rem(5px);
          height:rem(5px);
          border-radius: 50%;
          background: cl(primary);
        }
      }
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
  }
  &__title {
    position: absolute;
    bottom:-1.7vw;
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    //font-size: rem(200px);
    font-size: 10.4vw;
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
   &-relative{
      margin-top:2rem;
     position: relative;
   }
    &-small{
      font-size: 5.7vw;
      z-index: 3;
      bottom: -0.9vw;
    }
    &-medium{
      font-size: 7.8vw;
      z-index: 3;
      bottom: -1.3vw;
    }
    span {
      position: relative;
    }
    em {
      font-style: normal;
      position: relative;
      z-index: 2;
    }
    &__leaf {
      position: absolute;
      object-fit: contain;
      width:65%;
      height: 65%;
      &--right {
        transform: scaleX(-1);
        top: 0;
        left: 20%;
        z-index: 1;
      }
      &--left {
        top: 0;
        left: 65%;
        z-index: 3;
      }
    }
  }
}