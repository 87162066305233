.program {
	position: relative;
	overflow: hidden;
	padding: rem(185px 16px 140px);
	@include media-breakpoint-down(xs) {
		padding-top: 5.5rem;
		padding-bottom: 2rem;
	}
	h2 {
		font-size: rem(150px);
		line-height: 1;
		font-family: $fontTitle;
		white-space: nowrap;
		text-transform: uppercase;
		text-shadow: rem(0 9px 40px cl(black, .21));
		color:cl(white);
		text-align: center;
		margin: 0;
		position: absolute;
		left:0;
		top:rem(60px);
		width:100%;
		z-index: 1;
		@include media-breakpoint-down(lg) {
			font-size: rem(130px);

		}
		@include media-breakpoint-down(md) {
			font-size: rem(100px);
		}
		@include media-breakpoint-down(xs) {
			font-size: rem(50px);
		}
	}
	h3 {
		font-size: rem(40px);
		line-height: 1;
		font-weight: 700;
		text-transform: uppercase;
		color:cl(primary);
		text-align: center;
		padding: rem(0 16px);
		width:100%;
		z-index: 3;
		position: relative;
		@include media-breakpoint-up(sm) {
			position: absolute;
			left:0;
			top:8.3125rem;
		}
		@include media-breakpoint-up(lg) {
			top:rem(165px);
		}
		@include media-breakpoint-down(md) {
			br {
				display: none;
			}
		}
		@include media-breakpoint-down(xs) {
			margin-bottom: 2rem;
		}
	}
	&__leaf {
		@extend .pos-center;
		max-height: 90%;
		width:auto;
		z-index: 2;
	}
	&__list {
		list-style: none;
		display: flex;
		justify-content: space-between;
		//gap:rem(0 50px);
		flex-wrap: wrap;
		margin: rem(0 0 35px);
		@include media-breakpoint-up(xl) {
			justify-content: center;
			gap:rem(0 100px);
		}
		&--big-gap {
			@include media-breakpoint-up(xl) {
				gap:rem(0 325px);
			}
		}
		@include media-breakpoint-down(md) {
			gap:rem(35px);
		}
	}
	&__item {
		width:rem(550px);
		min-height: rem(145px);
		box-shadow: rem(0 9px 40px cl(black, .13));
		background: cl(white);
		display: flex;
		align-items: center;
		gap:rem(25px);
		font-size: rem(20px);
		line-height: 1.5;
		padding: rem(30px);
		border-radius: rem(8px);
		position: relative;
		z-index: 3;
		@include media-breakpoint-down(lg) {
			width:28rem;

		}
		@include media-breakpoint-down(md) {
			width:100%;
		}
		img {
			width: rem(45px);
			flex-shrink: 0;
			height: auto;
		}
	}
}