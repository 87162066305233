.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	transition: all .2s linear;
	.container{
		width: calc(100vw - 1rem);
		max-width: 100%;
		z-index: 0;
		position: static;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
	&.header--colored {
		background: cl(white);
		box-shadow: 0 0.1875rem 0.3125rem cl(black,.05);
		//.header__nav>ul>li.active>a,
		//.header__nav>ul>li>a:hover,
		//.header__nav>ul>li>a:focus {
		//	background: cl(secondary);
		//}
		.header__bottom-block{
			padding: rem(6px 0px 2px 0px);
		}
		.header-logo {
			width:18vw;
			max-width: rem(390px);
			@include media-breakpoint-down(md) {
				width:27vw;
			}
			@include media-breakpoint-down(sm) {
				width:25vw;
			}
			@include media-breakpoint-down(xs) {
				width:45vw;
			}
		}
	}
	&.header--inner {
		background: cl(white);
		box-shadow: 0 0.1875rem 0.3125rem cl(black,.05);
		//.header__nav>ul>li.active>a,
		//.header__nav>ul>li>a:hover,
		//.header__nav>ul>li>a:focus {
		//	background: cl(secondary);
		//}
		.header__bottom-block{
			padding: rem(6px 0px 2px 0px);
		}
		.header-logo {
			width:18vw;
			max-width: rem(390px);
			@include media-breakpoint-down(md) {
				width:27vw;
			}
			@include media-breakpoint-down(sm) {
				width:25vw;
			}
			@include media-breakpoint-down(xs) {
				width:45vw;
			}
		}
	}
	.marque-wrap{
		max-height: rem(26px);
		overflow: hidden;
		b{
			color: cl(white);
			font-weight: 400;
			margin-right: 2.5rem;
			font-size: rem(18px);
			line-height: rem(26px);
			@include media-breakpoint-down(sm) {
				font-size: rem(14px);
			}
		}
	}
	&-logo {
		width:26vw;
		max-width: rem(500px);
		flex-shrink: 0;
		transition: all 0.3s linear;
		display: block;
		@include media-breakpoint-down(md) {
			width:32vw;
		}
		@include media-breakpoint-down(sm) {
			width:32vw;
		}
		@include media-breakpoint-down(xs) {
			width:55vw;
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
	&__inner {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		flex-direction: column;
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
		&-top{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding-bottom: rem(18px);
			@include media-breakpoint-down(lg){
				padding-bottom: rem(0px);
			}
		}
	}
	&__logo-nav{
		position: absolute;
		display: none;
		max-width:70%;
		height: 6rem;
		object-position: center top;
		object-fit: contain;
		top:1rem;
		@extend .pos-centerX;
		@include media-breakpoint-down(lg) {
			display: block;
		}
	}
	&__nav {
		//width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: 8rem 0 0;
			background: cl(white);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		& > ul {
			display: flex;
			align-items: center;
			gap: rem(7px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap:0;
				display: block;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(18px);
					line-height: rem(28px);
					padding: rem(0 6px 13px 6px);
					border-radius: rem(3px);
					font-weight: 700;
					color:cl(black);
					text-transform: uppercase;
					transition: all .2s linear;
					position: relative;
					@media (max-width: 1440px){
						font-size: rem(16px);
					}
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1rem 2rem;
						text-align: center;
					}
					&:hover {
						color: cl(secondary);
						text-decoration: none;
					}
					&:before{
						content: "";
						position: absolute;
						width: 100%;
						background-color: cl(secondary);
						height: 3px;
						left: 0;
						bottom: 0;
						display: none;
						@include media-breakpoint-down(lg) {
							display: none!important;
						}

					}
				}
				&.active {
					& > a {
						&:before{
							display: block;
						}

					}
				}
				//&:hover {
				//	.drop {
				//		display: block;
				//	}
				//}
				.drop {
					position: absolute;
					top:rem(18px);
					padding-top: rem(14px);
					right:0;
					width:rem(250px);
					display: none;
					@include media-breakpoint-down(lg) {
						display: none;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					&:focus,
					&:visited{
						display: block;
					}
					ul {
						margin: 0;
						background: cl(primary);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						border-radius: rem(3px);
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						li {
							&:not(:last-child) {
								margin-bottom: 1px;
							}
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:cl(white);
								padding: rem(10px 10px 10px 20px);
								transition: all .2s linear;
								@include media-breakpoint-up(xl) {
									border-radius: rem(3px);
								}
								@include media-breakpoint-down(lg) {
									display: block;
									font-size: rem(17px);
									padding: 1rem 2rem;
									text-align: center;
									color:cl(black);
									background-color: cl(secondary,0.1);
									border-top:rem(1px solid cl(secondary, .05));
								}
								&:hover {
									text-decoration: none;
									background: cl(secondary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(secondary);
									}
									.drop {
										display: block;
									}
								}
								&:focus {
									~ .drop {
										display: block;
									}
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(secondary);
								}
							}
							&:visited {
								.drop {
									display: block;
								}
							}
							.drop {
								position: absolute;
								top:0;
								padding-top: 0;
								left: rem(-220px);
								width: rem(220px);
								display: none;
								@include media-breakpoint-down(lg) {
									display: block;
									position: static;
									width:100%;
									min-width: 100%;
									padding: 0;
									margin: 0;
								}
								&.opened{
									display: block!important;
								}
								&:focus,
								&:visited{
									display: block;
								}
								ul {
									margin: 0;
									background: cl(primary);
									padding: rem(2px);
									list-style: none;
									box-shadow:rem(1px 1px 6px cl(black, .21));
									border-radius: rem(3px);
									@include media-breakpoint-down(lg) {
										box-shadow: none;
										padding: 0;
										background: none;
									}
									li {
										padding: 0;
										&:before{
											display: none;
										}
										a {
											display: block;
											font-size: rem(15px);
											line-height: rem(18px);
											color:cl(white);
											padding: rem(10px 10px 10px 20px);
											transition: all .2s linear;
											@include media-breakpoint-up(xl) {
												border-radius: rem(3px);
											}
											@include media-breakpoint-down(lg) {
												display: block;
												padding: 1rem 2rem;
												font-size: rem(18px);
												line-height: rem(22px);
												text-align: center;
												color:cl(white);
												background: cl(white, 0.1);
												border-top: 1px solid cl(white);
											}
											&:hover {
												text-decoration: none;
												background: cl(secondary);
												color: cl(white);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: transparent;
													color: cl(secondary);
												}
											}
										}
										&.active {
											a {
												color: cl(white);
												text-decoration: none;
												background: cl(primary);
												@include media-breakpoint-down(lg) {
													text-decoration: none;
													background: cl(black, 0.1);
													color: cl(secondary);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&__buttons {
		position: relative;
		display: flex;
		gap:rem(16px);
		@include media-breakpoint-up(xl) {
			margin-left: rem(28px);
		}
		@include media-breakpoint-down(sm) {
			padding: 1rem 1rem 2rem;
			width:100%;
			margin-left: 0;
		}
		.btn {
			line-height: rem(50px);
			@include media-breakpoint-down(sm) {
				width:calc(50% - .5rem);
			}
		}
	}
	&__open-nav {
		display: none;
		position: relative;
		padding: 0;
		height:rem(20px);
		width:rem(26px);
		flex-shrink: 0;
		text-indent: -9999px;
		appearance: none;
		border: none;
		cursor: pointer;
		background: none;
		border:0;
		transition: all 0.3s ease  0.3s;
		z-index: 100;
		margin: rem(20px 0 0 32px);


		&:focus {
			outline: none;
		}
		@include media-breakpoint-down(lg){
			display: block;
			margin: rem(0px 0 0 32px);
		}
		span,
		span::before,
		span::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height:rem(2px);
			background-color: cl(secondary);
			content: "";
			transition: all 0.3s ease;
		}
		span {
			top:rem(9px);
			&:before {
				top:rem(-7px);
			}
			&:after{
				bottom:rem(-7px);
			}
		}
		&--active {
			&:before {
				display: none;
			}
			span {
				background: none;
				&:before {
					top: 0;
					transform:rotate(45deg);
					background: cl(secondary);
				}
				&:after{
					bottom: 0;
					transform:rotate(-45deg);
					background: cl(secondary);
				}
			}
		}
	}
	.header-social{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		column-gap: 1.5rem;
		&.header-social-menu{
			display: none;
			@include media-breakpoint-down(md) {
				justify-content: center;
				width: 100%;
				column-gap: 2rem;
				padding: 1rem;
				display: flex;
				background-color: cl(primary);
			}
		}
		a{
			color: cl(white);
			padding: 0.25rem 0;
			&:hover{
				text-decoration: none;
				color: cl(secondary);
			}
			i{
				font-size: rem(22px);
				//@include media-breakpoint-down(sm) {
				//	margin-left: 0;
				//	font-size: rem(28px);
				//}
			}

		}
	}

	.select-lang {
		position: relative;
		.open-lang {
			//border-radius: 50%;
			background: cl(primary);
			color:cl(white);
			width:rem(25px);
			height: rem(25px);
			//border:1px solid  cl(primary);
			justify-content: center;
			align-items: center;

			display: flex;
			@include media-breakpoint-down(xs) {
				margin-left: 0.5rem;
			}
			&.active {
				color: cl(primary);
				//background: cl(white);
			}
			i {
				font-size:rem(15px);
			}
			img{
				width: rem(20px);
			}
		}
		.lang-inner {
			position: absolute;
			right:0;
			left: unset;
			top:-7rem;
			width:rem(300px);
			padding: 1rem;
			background:  cl(white);
			transition: all 0.3s ease;
			text-align: center;
			z-index: 1000;
			&.show {
				top:2rem;
			}
			@include media-breakpoint-down(xs) {
				position: fixed;
				left:0;
				top:-100%;
				width:100%;
				padding: 1rem;
				background:  cl(white);
				transition: all 0.3s ease;
				text-align: center;
				z-index: 1000;
				&.show {
					top:2rem;
				}
			}
		}
		.goog-te-gadget-simple {
			background: transparent;
			border:0!important;
			padding: 0!important;
			.goog-te-menu-value {
				color: cl(white);
			}
		}
	}
	.goog-te-gadget-simple .goog-te-menu-value span {
		text-decoration: none;
		font-size: rem(15px);
		color: cl(primary);
		font-family: $font;
		font-weight: 400;
	}
	#translate-button.button {
		position: relative;
		font-size: rem(15px);
		color: cl(primary);
		font-family: $font;
		font-weight: 400;
		text-transform: uppercase;
		border: 0;
		border-radius: 0;
		padding: 0;
		margin: 0;
		white-space: nowrap;
		@include media-breakpoint-down(xs) {
			font-size: rem(14px);
			color: cl(white);
		}
		&:focus{
			outline: 0;
		}
		i{
			font-size: rem(13px);
			margin-left: 0.1rem;
		}

	}
	#googleTranslate {
		opacity: 0;
		width: 100px !important;
		min-height: 20px;
		top: 0;
		left: unset!important;
		right: 0;
		position: absolute;
		cursor: pointer;
		border: 0!important;
	}
	.header__top-block{
		padding: rem(5px 0);
		width: 100%;
		background-color: #34497e;
	}
	.header__bottom-block{
		width: 100%;
		padding: rem(12px 0px 2px 0px);
		&-inner{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			@include media-breakpoint-down(md) {

			align-items: center;
			}
		}
	}
}

.not-modal-toggle{
	width:  rem(50px);
	height: rem(50px);
	border-radius: 50%;
	color: cl(white);
	background-color: cl(primary);
	border: 1px solid cl(primary);
	box-shadow: 3.517px 8.285px 21px 0px rgba(0, 0, 0, 0.14);
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	font-size: rem(25px);
	align-items: center;
	margin-left: 1rem;

	&:hover{
		background-color: cl(white);
		color: cl(primary) !important;
		text-decoration: none;
	}
}

@include media-breakpoint-up(lg) {
	.header__nav ul li:hover > .drop {
		display: block!important;
	}
	.header__nav>ul>li>a ~ .drop:focus-within {
		display: block!important;
	}
	.header__nav>ul>li>a ~ drop>ul>li ~.drop:focus-within {
		display: block!important;
	}
	.header__nav>ul>li .drop ul li .drop:focus-within {
		display: block!important;
	}
}

.header__nav>ul>li .drop>ul>li:hover > .drop{
	display: block;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.header__nav>ul>li .drop>ul>li:hover {
	background-color: cl(primary);
	@include media-breakpoint-down(lg){
		background-color: transparent;
		color: cl(secondary);
		a {
			color: cl(secondary);
		}
	}
	a {
		color: cl(white);
		@include media-breakpoint-down(lg){
			color: cl(secondary);
		}
	}
}

a{
	&:focus{
		outline: 1px dashed cl(secondary);
		box-shadow: none;
	}
}