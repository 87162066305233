/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  &--home {
    margin: rem(190px 0px 0 0px);
    padding: rem(0px 102px 0 70px);
    @include media-breakpoint-down(md) {
      padding: rem(0px 30px 0 30px);
    }
    @include media-breakpoint-down(sm) {
      margin: rem(130px 0px 0 0px);
    }
    .background-deco{
      position: absolute;
      right: 0;
      width: calc(100vw - 10.625rem);
      bottom: 0;
      height: 100%;
      //object-fit: cover;
      @include media-breakpoint-down(lg) {
        width: 100%;
        object-fit: cover;
      }
    }
    .hor-leaf{
      position: absolute;
      left: rem(230px);
      width: rem(345px);
      bottom: 0;
      @include media-breakpoint-down(lg) {
        width: 18vw;
       left: 30vw;
      }
      @include media-breakpoint-down(md) {
        left: 10vw;
        bottom: rem(40px);
      }
      @include media-breakpoint-down(sm) {
        width: 28vw;
        bottom: rem(60px);
        left: 2rem;
      }
    }
    .container{
      width: 100%;
      max-width: 100%;
    }
    .banner__links{
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.625rem;
      @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
        margin-top: 1rem;
        text-align: center;
        justify-content: center;
      }
      .btn{
        min-width: auto;
        width: auto;
        margin-top: 0rem;
      }
      .banner__link{
        img{
          object-fit: contain;
          height: 70px;
        }
      }
    }
    .banner__ttl {
      padding-top: rem(100px);
      display: block;
      font-family: $fontTitle;
      font-size: rem(70px);
      line-height: rem(60px);
      margin: rem(0 0 5px);
      font-weight: normal;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: rem(50px);
        line-height: rem(50px);
        text-align: left;
      }
      @include media-breakpoint-down(md) {
        padding-top: rem(30px);
        padding-bottom: rem(30px);
        font-size: rem(70px);
        text-align: center;
        line-height: rem(60px);
      }
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
        text-align: center;
        line-height: rem(40px);
      }
    }
  }
  &--page {
    height: rem(550px);
    padding-top: rem(150px);
    background-position: 50% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @include media-breakpoint-down(lg) {
      background-attachment: scroll;
      height: rem(450px);
      padding-top: rem(100px);
    }
    @include media-breakpoint-down(sm) {
      height: rem(400px);
      padding-top: rem(100px);
    }
    &:before{
      content: "";
      @extend .full;
      background: cl(dark-blue, 0.51);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(60px);
      font-weight: normal;
      text-transform: uppercase;
      font-family: $fontTitle;
      margin: 0;
      @include media-breakpoint-down(xs) {
        font-size: rem(50px);
      }
    }
  }
  &__social{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: rem(10px);
    margin-top: rem(32px);
    margin-bottom: rem(40px);
    border-radius: (30px 30px 30px 30px);
    background-color: cl(white);
    border:1px solid rgb(180, 223, 240);
    padding: rem(12px 20px);
    width: fit-content;
    @include media-breakpoint-down(md) {
      margin: 1rem auto 2rem;
      text-align: center;
      justify-content: center;
    }
    span{
      font-weight: 700;
      font-size: rem(18px);
      text-transform: none;
    }
    img{
      width: rem(36px);
    }
  }
  .banner-button {
    background: cl(primary);
    color:cl(white);
    font-size: rem(18px);
    line-height: rem(64px);
    font-family: $font;
    text-transform: none;
    letter-spacing: rem(1px);
    font-weight: 700;
    border: rem(1px solid cl(primary));
    border-radius: rem(33px);
    padding: rem(0 40px 0 20px);
    outline: 0;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap:rem(10px);
    &:hover,
    &:focus {
      border-color:cl(primary);
      background: cl(primary, 0.9);
      color:cl(white);
      text-decoration: none;
      box-shadow:none;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
    img {
      width:rem(57px);
      height: auto;
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:after{
      content: "";
      @extend .full;
      background: cl(dark-blue, 0.1);
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(primary);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-top: rem(50px);
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    p {
      margin: 0;
    }
    .btn {
      width:rem(375px);
      font-size: rem(18px);
      margin-top: rem(50px);
      letter-spacing: rem(2px);
    }
  }
  &__ttl {
    display: block;
    font-family: $fontTitle;
    font-size: rem(70px);
    line-height: rem(60px);
    margin: rem(0 0 5px);
    font-weight: normal;
    text-transform: uppercase;
  }

  &__sub {
    display: block;
    font-family: $fontTitle;
    font-size: rem(50px);
    line-height: 1.2;
    font-weight: normal;
    margin: rem(0 0 35px);
    @include media-breakpoint-down(sm) {
      font-size: rem(30px);
    }
  }
  &__txt {
    display: block;
    font-size: rem(30px);
    line-height: 1.3;
    margin: rem(0 0 38px);
    @include media-breakpoint-down(sm) {
      font-size: rem(20px);
    }
  }
  &__slider {
    border-radius: rem(95px 0 95px 95px);
    overflow: hidden;
    box-shadow:rem(-18.026px 30.001px 60px 0px rgba(0, 0, 0, 0.34));
    width:100%;
    max-width: rem(1070px);
    margin-left: auto;
    margin-bottom: rem(35px);
    @include media-breakpoint-down(xs) {
      border-radius: rem(50px 0 50px 50px);

    }

  }
  &__image {
    // height: rem(626px);
    padding-top: 58%;
    position: relative;

    img {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      display: block;
      border-radius: rem(95px 0 95px 95px);
      object-fit: cover;
      @include media-breakpoint-down(xs) {
        border-radius: rem(50px 0 50px 50px);
      }
    }
  }
  &__panel {
    display: flex;
    justify-content: flex-end;
    gap:rem(25px);
    padding-bottom: rem(30px);
  }
  &__button {
    width:rem(50px);
    height: rem(50px);
    overflow: hidden;
    border-radius: 50%;
    background: cl(secondary);
    color:cl(white);
    border:0;
    font-size: rem(25px);
    box-shadow:rem(0 9px 27px cl(black, .38));
    &:hover,
    &:focus {
      background: cl(primary);
    }
    i{
      font-size: rem(25px);
    }
  }
  &__leaf {
    position: absolute;
    width: rem(150px);
    height: auto;
    transform: rotate(70deg);
    bottom: rem(20px);
    right: rem(450px);
    z-index: 101;
    @include media-breakpoint-down(xs) {
      right:50%;
      width: rem(100px);
      bottom: rem(40px);
    }
  }
}
/* banner end */

