.content-flex{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row-reverse;
  font-size: rem(18px);
  line-height: rem(28px);
  padding: rem(55px 0 0px 0);
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  .account-mobile-toggle{
    margin-bottom: 0.675rem;
    width: 100%;
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .left-menu{
    flex-shrink: 0;
    width: 18.75rem;
    margin-right: 2.5rem;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right:0;
      margin-bottom: 2rem;
    }
    &:before{
      position: absolute;
      content: "";
      left: rem(-110px);
      width: rem(190px);
      height: rem(211px);
      top: 0;
      background-image: url("../img/svg/SomersetOhio_Logoleafe-left.svg");
      background-size: contain;
      background-position: left top;
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        display: none;
        min-height: unset;
      }
    }
    ul{
      border-radius: rem(8px);
      background-color:cl(white);
      position: relative;
      width: 100%;
      list-style: none;
      margin-bottom: 1rem;
      padding: rem(23px 0);
      min-height: rem(220px);
      box-shadow: -18.026px 30.001px 60px 0px rgba(0, 0, 0, 0.18);
      z-index: 2;
      @include media-breakpoint-down(md) {
        display: none;
        min-height: unset;
      }

      li{
        color: cl(text);
        font-size: rem(18px);
        background-color: transparent;
        line-height: rem(24px);
        padding: rem(8px 20px 8px 24px);

        &.active{
          background-color:cl(primary);
          color:cl(white);
          a{
            color:cl(white);
          }
        }
        &:hover{
          background-color:cl(secondary);
          a{
            color:cl(white);
          }
        }
        a{
          color: cl(text);
          display: block;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

  }
  .main-part{
    width: 86%;
    font-size: rem(18px);
    color: cl(text);
    line-height: rem(28px);
    @include media-breakpoint-down(md) {
      width: 100%;
      order: 1;
    }
    .border-top {
      border-top: 1px solid #dee2e6!important;
    }
    &.main-part-width{
      width: calc(100% - 21.25rem);
      @include media-breakpoint-down(md) {
        width: 100%;
        order: 1;
      }
    }
    h2{
      @extend .ttu;
      font-size: rem(40px);
      font-weight: 700;
      color: cl(primary);
    }
    h3{
      font-size: rem(30px);
      font-weight: 700;
      color: cl(primary);
    }
    h4{
      font-size: rem(24px);
      font-weight: 700;
      color: cl(primary);
    }
    .frame-wrap{
      margin-top: -3rem;
      max-width: 90vw;
      height: 670px!important;
      @include media-breakpoint-down(md) {
        height: 1000px!important;
      }
    }
    p{
      margin-bottom: rem(20px);
    }
    //a{
    //  color: cl(secondary);
    //}
    .first-block{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1.5rem;
      justify-content: space-between;
      .left-side{
        width: calc(100% - 25.625rem);
        @include media-breakpoint-down(md) {
          width: 100%;
          padding-right: unset;
          order: 1;
        }
      }
      .image-side{
        width: rem(410px);
        padding-left: rem(40px);
        @include media-breakpoint-down(md) {
          width: 100%;
          padding:0;
        }
        img{
          border-radius: rem(8px);
          box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.13);
          max-width: 100%;
          margin: 0 auto 1rem;
          max-height: rem(400px);
          object-fit: contain;
          display: block;
        }

      }
    }
    .more-text{
      h2{
        @include media-breakpoint-down(xs) {
         text-align: center;
        }
      }
      img{
        max-width:100%;
        max-height: 60vh;
        object-fit: contain;
        display: block;
        margin: 0 auto 1.5rem;
        object-position: center top;
        border-radius: rem(8px);
        box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.13);
      }
      ul{
        list-style: none;
        li{
          position: relative;
          padding: rem(5px 0 5px 25px);
          &:before{
            position: absolute;
            content: "";
            border-radius: 50%;
            width: 3px;
            height: 3px;
            background-color: cl(primary);
            left: 0;
            top: 1.2rem;
          }
        }
      }
      ol{
        padding-left: 3rem;
        li {
          position: relative;
          padding: rem(5px 0 5px 0px);
        }
      }
      .logo-link{
        width: rem(140px);
        margin-right: 2rem;
        @include media-breakpoint-down(xs) {
          width: rem(100px);
          margin-right: 1rem;
        }
        img{
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          display: block;
          margin: 0;
          object-position: center top;
        }
      }
      .video-holder{
        width: 85%;
        margin: 0 auto 2rem;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        video{
          width: 100%;
          object-fit: cover;
          box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        }
      }
      &.news-inner{
        a{
          color: cl(primary);
        }
        .btns-wrap{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          .btn{
            margin-right: 3rem;
            margin-top: 2rem;
            @include media-breakpoint-down(sm) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
      .images-box{
        display: flex;
        flex-wrap:wrap;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 3%;
        img{
          max-width: 47%;
          max-height: 70vh;
          object-fit: contain;
          display: block;
          margin: 0 auto 1.5rem;
          object-position: center top;
        }
      }
      .academies-block{
        margin: 0 auto;
        .item{
          border-radius: rem(8px);
          background-color: rgb(255, 255, 255);
          box-shadow: 0.907px 12.968px 27px 0px rgba(0, 0, 0, 0.25);
          width: 100%;
          font-size: rem(16px);
          line-height: rem(26px);
          padding: rem(15px 30px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: rem(10px);
          color: cl(text);
          @include media-breakpoint-down(sm) {
            flex-direction: column;
          }
          .news-item-inner{
            padding-right: rem(25px);
            @include media-breakpoint-down(sm) {
              order:1;
              padding-right: 0;
              max-height: unset;
            }

          }
          .ttl{
            font-weight: 700;
            font-size: rem(18px);
            line-height: rem(30px);
            margin-bottom: rem(16px);
            color: cl(text);
          }
          .news-item-img{
            flex-shrink: 0;
            @include media-breakpoint-down(sm) {
              margin: 0 auto;
            }
          }
          ul{
            li{
              padding:0;
              &:before{
                display: none;
              }
            }
          }
          img{
            margin: 0!important;
            object-fit: cover!important;
            width: rem(140px)!important;
            height: rem(140px)!important;
            object-position: center;
            max-width: 100%!important;
            @include media-breakpoint-down(sm) {
              margin: 0 auto 1rem!important;
              width: rem(240px)!important;
              height: rem(200px)!important;
            }
          }
        }
      }
    }


    .files-block{
      max-width: 100%;
      margin: 0 auto 2rem;
      .item-download-file{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(214, 214, 214);
        padding: rem(13px 23px);
        background-color: cl(white);
        margin-bottom: rem(12px);
        transition: 0.2s ease-in-out;
        font-size: rem(16px);
        font-family: $font;
        line-height: rem(25px);
        border-radius: rem(4px);
        @include media-breakpoint-down(md) {
          font-size: rem(16px);
          line-height: rem(26px);
          padding: rem(12px 15px);
        }
        &:hover{
          border: 1px solid cl(primary);
          text-decoration: none;
          color: cl(secondary);
          .name{
            color: cl(secondary);
          }
          .download-pic{
            color: cl(secondary);
          }
        }
        .text-cont{
          overflow-x: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          max-width: calc(100% - 3rem);
        }
        .file-img{
          height: rem(25px);

          margin: 0;
          margin-right: rem(25px);
          @include media-breakpoint-down(md) {
            height: rem(25px);
            margin-right: rem(15px);
          }
        }
        .name{
          color: cl(text);
          white-space: nowrap;
          transition: 0.2s ease-in-out;
          font-weight: 700;
        }
        .download-pic{
          position: relative;
          color: cl(primary);

        }
      }
    }
  }
}

.search-block{
  position: relative;
  margin:rem(0 0 0 0);
  width: rem(290px);
  height: rem(38px);
  @include media-breakpoint-down(xs) {
    order: 1;
    width: 100%;
    margin: 1rem 0;
  }
  .sbm{
    @extend .pos-centerY;
    border:0;
    position: absolute;
    width: rem(36px);
    height: rem(36px);
    background-color: transparent;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(0 10px);
    &:focus {
      outline: none;
      box-shadow:none;
      outline: dashed 1px rgb(214, 214, 214);
    }

    img{
      width: rem(20px);
      height: rem(20px);
      margin: 0;
    }
  }

  .form-control{
    display: block;
    width:100%;
    height: rem(38px);
    border-radius: rem(4px);
    background: cl(white);
    border:rem(1px solid rgb(214, 214, 214));
    padding:rem(0 16px);
    font-size:rem(15px);
    color:cl(text);
    @include placeholder( #bababa);
    &:focus {
      outline: none;
      box-shadow:none;
      background: cl(white);
      border-color:cl(primary);
    }
  }
}

.inner-page{
  padding: rem(0px 0 50px 0);
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  .separation-mission{
    img{
      width: 100%;
    }
  }
 }