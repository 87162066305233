.purpose {
  padding: rem(70px 290px 130px 50px);
  position: relative;
  //overflow: hidden;
  @include media-breakpoint-down(lg) {
    padding: rem(50px 16px 120px);
  }
  @include media-breakpoint-down(xs) {
    padding: 3rem 1rem 4rem;
  }
  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__image {
    width:rem(570px);
    height: rem(450px);
    flex-shrink: 0;
    overflow: hidden;
    border-radius: rem(6px);
    box-shadow: rem(0 9px 40px cl(black, .13));
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width:100%;
      height: 20rem;
    }
    img {
      width:100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  &__text {
    font-size: rem(20px);
    line-height: 1.5;
    padding-top: rem(55px);
    h2 {
      font-size: rem(40px);
      text-transform: uppercase;
      color:cl(primary);
      font-weight: 700;
      margin: rem(0 0 30px);
    }
  }
  &__slogan {
    position: absolute;
    bottom: rem(-33px);
    left:0;
    width:100%;
    text-shadow: rem(0 9px 40px cl(black, .21));
    color:cl(white);
    text-align: center;
    margin: 0;
    font-size: rem(200px);
    line-height: 1;
    font-family: $fontTitle;
    white-space: nowrap;
    text-transform: uppercase;
    z-index: 3;
    @include media-breakpoint-down(lg) {
      font-size: 10vw;
    }
    //@include media-breakpoint-down(md) {
    //  font-size: rem(130px);
    //}
    @include media-breakpoint-down(xs) {
      //font-size: rem(50px);
      bottom: -0.5rem;
    }
    span {
      position: relative;
      img {
        position: absolute;
        top: -15%;
        left: -30%;
        width:100%;
        height: 100%;
        object-fit: contain;
        transform: scaleX(-1);
      }
    }
  }
  &__words {
    display: flex;
    align-items: flex-end;
    margin: rem(0 0 0 -42px);
    @include media-breakpoint-down(xs) {
      margin: 2rem 0 0;
    }
    span {
      font-size: rem(60px);
      font-weight: 700;
      text-transform: uppercase;
      color:white;
      //text-shadow:1px 1px cl(primary), -1px 1px cl(primary), 1px -1px cl(primary), -1px -1px cl(primary);
      -webkit-text-stroke: 1px cl(primary);
      -webkit-text-fill-color: white;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      position: relative;
      padding: rem(0 10px 20px);
      --margin: 1.750rem;
      margin-bottom: var(--margin);
      &:nth-child(1) {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      &:nth-child(1) {
        margin-bottom: calc(var(--margin) * 4);
      }
      &:nth-child(2) {
        margin-bottom: calc(var(--margin) * 3);
      }
      &:nth-child(3) {
        margin-bottom: calc(var(--margin) * 2);
      }
      &:before {
        content: "";
        position: absolute;
        left:0;
        top: 100%;
        width:100%;
        height: rem(4px);
        background: cl(primary);
      }
      &:after {
        content: "";
        position: absolute;
        right:0;
        top: 100%;
        width:rem(4px);
        height: rem(32px);
        background: cl(primary);
      }
    }
  }
}