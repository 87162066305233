.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh;
	background: cl(white);
	font-family: $font;
	line-height: 1.1;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}

.no-scroll {
	overflow: hidden;
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 98.125rem;
	max-width: var(--size);
	&--small {
		--size: 92.125rem;
	}
	&--xs {
		--size: 77rem;
	}
	&--big{
		--size: 98.125rem;
	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}
.application {
	border-bottom: rem(4px solid cl(primary));
	min-height: 100vh;
	&__title {
		background: cl(primary);
		color:cl(white);
		text-align: center;
		padding: 98px 0 30px;
		h1 {
			font-size: rem(60px);
			font-weight: normal;
			text-transform: uppercase;
			font-family: $fontTitle;
			margin: 0;
			@include media-breakpoint-down(xs) {
				font-size: rem(50px);
			}
		}
	}
}


@-webkit-keyframes round {
	0% {transform: rotate(0);}
	100% {transform: rotate(180deg)}
}
@keyframes round{
	0% {transform: rotate(0);}
	100% {transform: rotate(180deg)}
}


body .uwy.userway_p1 .uai {
	top: 92% !important;
	@include media-breakpoint-down(xs) {
		top: 85% !important;
	}
}

h2{
	font-size: rem(40px);
	color: cl(primary);
	@extend .ttu;
	line-height: 1;
	font-family: $fontTitle;
	margin-bottom: rem(30px);
}
main{
	min-height: 100vh;
	overflow-x: hidden;
}

.custom-tabs {
	.nav {
		&-tabs {
			border:0;
			gap:rem(5px);
		}
		&-item {
			margin: 0;
		}
		&-link {
			border:1px solid cl(secondary);
			padding: rem(10px 20px);
			font-size: rem(16px);
			font-weight: 600;
			border-radius: rem(30px);
			color:cl(secondary);
			transition: all .3s ease;
			&.active {
				border:1px solid cl(secondary);
				background: cl(secondary);
				color:cl(white);
			}
			&:is(:hover,:focus) {
				border:1px solid cl(secondary);
				background: cl(secondary);
				color:cl(white);
			}
		}
	}
	.tab {
		&-content {
			padding: rem(30px 20px 10px);
			font-size: rem(16px);
			line-height: 1.4;
			p {
				margin: rem(0 0 16px);
			}
		}
		&-pane {

		}
	}
}