.btn {
  @extend .inline-b;
  @extend .ttu;
  background: cl(primary);
  color:cl(white)!important;
  font-size: rem(20px);
  line-height: rem(54px);
  font-family: $font;
  font-weight: 700;
  border: rem(1px solid cl(primary));
  cursor: pointer;
  border-radius: rem(27px);
  padding: rem(0 30px);
  outline: 0;
  min-width: rem(160px);
  white-space: nowrap;
  &:hover,
  &:focus {
    border-color:cl(primary);
    background: cl(primary, 0.9);
    color:cl(white)!important;
    text-decoration: none;
    box-shadow:none;
  }
  &--primary-outline {
    background: none;
    color:cl(primary)!important;
    border-color:cl(primary);
    &:hover {
      color:cl(primary)!important;
      background: cl(primary, 0.1);
    }
  }
  &--secondary {
    background: cl(secondary);
    border-color:cl(secondary);
    background: cl(secondary);
    color:cl(white)!important;
    &:hover {
      background: cl(secondary, .8);
      border-color:cl(secondary);
      color:cl(white)!important;
    }
  }
  &--white {
    background: cl(white);
    border-color:cl(white);
    color:cl(secondary)!important;
    &:hover {
      background: cl(white, .8);
      border-color:cl(white);
      color:cl(secondary)!important;
    }
  }
  &--secondary-outline {
    background: none;
    color:cl(secondary)!important;
    border-color:cl(secondary);
    &:hover {
      color:cl(secondary)!important;
      background: cl(secondary, 0.1);
    }
  }
  &--black {
    background: cl(black);
    border-color:cl(black);
    &:hover {
      background: cl(black, .8);
    }
  }
  &--white-outline {
    background: none;
    color:cl(white)!important;;
    border-color:cl(white);
    &:hover,
    &:focus {
      color:cl(white)!important;;
      background: cl(white, 0.1);
      border-color:cl(white);
    }
  }
  &--outline-primary {
    background: none;
    color:cl(primary)!important;;
    border-color:cl(primary);
    &:hover,
    &:focus {
      color:cl(primary)!important;;
      background: cl(primary, 0.1);
      border-color:cl(primary);
    }
  }
  &.btn--small{
    line-height: rem(50px);
    text-transform: none;
  }
}