.news {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  .decor {
    position: absolute;
    &--top-right {
      span {
        &:nth-child(2) {
          top:rem(-5px);
          left:0;
        }
      }
    }
    &--bottom-left {
      span {
        &:nth-child(2) {
          bottom:rem(10px);
          left:rem(11px);
        }
      }
    }
    span {
      display: block;
      &:nth-child(1) {
        @include circle(113px);
        background: linear-gradient(135deg, #daeff7 0%,rgba(255,255,255,1) 100%);
      }
      &:nth-child(2) {
        position: absolute;
        @include circle(40px);
        background: linear-gradient(135deg, rgba(189,227,242,0.75) 0%, rgba(189,227,242,0.75) 80%);
        opacity: .7;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap: 2rem;
    gap:var(--gap);
    .news__item {
      --cols: 3;
      flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
      @include media-breakpoint-down(xs) {
        --cols: 1;
      }
    }
  }
  &__wrap {
    padding: rem(50px 0 118px 155px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
      padding: 3rem 0 6rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 2rem 0 6rem;
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap:rem(10px);
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__text {
    position: relative;
    z-index: 2;
    // width:100%;
    flex-shrink: 0;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    h2 {
      text-transform: uppercase;
      font-family: $fontTitle;
      font-size: rem(40px);
      color:cl(primary);
      margin: rem(0 0 40px);
    }
  }
  &__side {
    width:100%;
    margin: rem(30px 0 0);
    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }
  &__all {
    position: relative;
    z-index: 200;
    margin: rem(-20px 0 20px);
  }
  &__slider {
    position: relative;
    z-index: 4;
    .news__item {
      margin: rem(30px 20px 50px 20px);
      width:rem(360px);
      @include media-breakpoint-down(xs) {
        margin: rem(30px 10px 50px 10px);
      }
    }
  }
  &__buttons {
    display: flex;
    gap:rem(13px);
    margin-top: rem(96px);
    @include media-breakpoint-down(md) {
      justify-content: center;
      margin-top: 0;
    }
  }

  &__item {
    border-radius: rem(0px 50px 50px 50px);
    overflow: hidden;
    background: cl(white);
    box-shadow:rem(0 5px 20px cl(black, .24));
  }
  &__image {
    height: rem(220px);
    display: block;
    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
    }
  }
  &__hold {
    padding: rem(26px 32px 30px 32px);
    font-size: rem(16px);
    line-height: rem(34px);
    color:#52555b;
    h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      font-size: rem(20px);
      line-height: rem(28px);
      height: rem(57px);
      color:#0a264a;
      font-family: $font;
      font-weight: 700;
      a {
        color:#0a264a;
        transition: all .2s linear;
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

      margin: rem(0 0 15px);

    }
    &-wrap{
      overflow: hidden;
      height: rem(130px);
      margin-bottom: 0.5rem;
    }
  }
  &__more {
    transition: all .2s linear;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .circle{
      font-size: rem(12px);
      width: 1.25rem;
      margin-left: 0.375rem;
      height: 1.25rem;
      border-radius: 50%;
      flex-shrink: 0;
      color: cl(white);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #1c3f95;
    }
    &:hover {
      text-decoration: none;
      color:#1c3f95;
    }
  }
  &__banner {
    border-radius: rem(45px 0);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    width:rem(390px);
    overflow: hidden;
    display: block;
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
    img {
      width:100%;
      height: auto;
    }
  }
  &__space {
    @include media-breakpoint-down(md) {
      height: 5rem;
      background: white;
    }
    @include media-breakpoint-down(xs) {
      height: 14rem;
    }
  }
  &__wave {
    position: absolute;
    width:100%;
    &--top {
      top: rem(-5px);
    }
    &--bottom {
      bottom: rem(-5px);
    }
    img {
      width:100%;
      height: auto;
    }
  }

  &__waves {
    position:absolute;
    left:0;
    bottom: 0;
    width: 100%;
    height:rem(100px);
    margin-bottom:rem(-1px); /*Fix for safari gap*/
    z-index: 2;
    @include media-breakpoint-down(xs) {
      height:rem(80px);
      //margin-bottom:rem(-3px);
    }
    svg,
    img{
      display: block;
      width:100%;
      height: 100%;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(247px);
    height: auto;
    bottom: 1rem;
    left: 68%;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      left: 24%;
      bottom: 4rem;
      z-index: 1;
    }
  }
  .decor {
    top: rem(7px);
    left:rem(66px);
  }
  &__bg-image{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 76vw;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  &__button {
    @include circle(50px);
    background: cl(white);
    border:0;
    color:cl(secondary);
    box-shadow: 0px 9px 16px 0px rgba(0, 0, 0, 0.21);
    font-size: rem(32px);
    transition: all .2s linear;
    &:hover {
      background: cl(primary);
    }
    &:focus{
      outline: none;
    }
  }
}
.home--students-section{
  padding: rem(120px 0 90px 0);
  position: relative;
  @include media-breakpoint-down(md) {
    padding: rem(50px 0 50px 0);
  }
  @include media-breakpoint-down(sm) {
    padding: rem(60px 0 50px 0);
  }
  .block-deco-2{
    position: absolute;
    z-index: 0;
    right: rem(-470px);
    width: rem(646px);
    @extend .pos-centerY;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .block-deco-3{
    position: absolute;
    z-index: 1;
    width: rem(273px);
    top: 60%;
    right: 1rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .image-side{
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
    .img-bg{
      position: absolute;
      width: 53vw;
      top: 5vw;
      left: -6.4vw;
    }
    .image-holder{
      width: 81%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: cl(white);
      z-index: 3;
      border-radius: rem(0px 50px 50px 50px);
      @include media-breakpoint-down(lg) {
        width: 90%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      &:before{
        content:"";
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 100%;
        z-index: 2;
        left:0;
        background-image: url("../img/shadow-1.png");
        background-size: cover;
        background-position: center bottom;
      }
      .main-image{
        width: 100%;
        border-radius: rem(0px 50px 50px 50px);
        box-shadow: rem(-18.026px 30.001px 60px 0px rgba(0, 0, 0, 0.34));
        display: block;
        overflow: hidden;

      }
      .ttl-holder{
        position: absolute;
        z-index: 4;
        bottom: 0;
        padding-bottom: rem(18px);
        h2{
          margin-bottom: 0;
          color: cl(white);
          position: relative;
          z-index: 2;
        }
      }

    }
  }
  .text-side{
    max-width: rem(480px);
    font-size: rem(26px);
    line-height: 1.5;
    color:#0a264a;
    position: relative;
    z-index: 2;
    padding-bottom: 5rem;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding-bottom: 0;
    }
    @include media-breakpoint-down(xs) {
      padding-bottom: 0;
    }
    p{
      margin-bottom: 2rem;
    }
    .btn{
      margin-top: rem(20px);
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
  .block-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .big-side{
      width: 63%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .small-side{
      width: 37%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
.home--events-section{
  padding: rem(100px 0 60px 0);
  position: relative;
  @include media-breakpoint-down(sm) {
    padding: rem(50px 0 60px 0);
  }
  .decor {
    position: absolute;
    left: rem(-90px);
    top: rem(-50px);
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &--top-right {
      span {
        &:nth-child(2) {
          top:rem(-5px);
          left:0;
        }
      }
    }
    &--bottom-left {
      span {
        &:nth-child(2) {
          bottom:rem(10px);
          left:rem(11px);
        }
      }
    }
    span {
      display: block;
      &:nth-child(1) {
        @include circle(113px);
        background: linear-gradient(135deg, #daeff7 0%,rgba(255,255,255,1) 100%);
      }
      &:nth-child(2) {
        position: absolute;
        @include circle(40px);
        background: linear-gradient(135deg, rgba(189,227,242,0.75) 0%, rgba(189,227,242,0.75) 80%);
        opacity: .7;
      }
    }
  }
  .ttl-hold{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    h2{
      position: relative;
      z-index: 4;
    }
    .btns-wrap{
      flex-shrink: 0;
      position: relative;
      z-index: 4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: rem(10px 20px);
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
      .btn{
        min-width: rem(190px);
        @include media-breakpoint-down(sm) {
          width: 48%;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .events-block{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items:stretch;
    position: relative;
    gap:rem(30px 95px);
    padding-left: rem(55px);
    margin-top: rem(0px);
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      margin-top: 2rem;
    }
    .img-deco-3{
      position: absolute;
      @extend .pos-centerY;
      width: rem(355px);
      right: rem(-100px);
    }
  }
  .box{
    position: relative;
    z-index: 4;
    border-radius: rem(0px 0px 0px 20px);
    background-color: cl(white);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    width: 100%;
    flex-grow: 1;
    padding: rem(35px 65px 35px 65px);
    font-size: rem(16px);
    line-height: rem(26px);
    margin-top: rem(30px);
    color: #333333;
    @include media-breakpoint-down(md) {
      width: calc(50% - 3.7rem);
      flex-grow: 0;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &:before{
      content: "";
      top:0;
      position: absolute;
      height: rem(6px);
      background-color: #245f49;
      width: 100%;
      left: 0;
    }
    &:after{
      content: "";
      top:0;
      position: absolute;
      height: 66%;
      right: 0;
      background-color: #245f49;
      width: 1px;
    }
    .ttl{
      font-weight: 700;
      font-size: rem(20px);
      line-height: rem(34px);
      margin-bottom: rem(20px);
      color: #0a264a;
      display: block;
    }

    .event-date{
      border-radius: rem(0px 20px 20px 20px);
      background-color:rgb(52, 73, 126);
      box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
      min-width: rem(80px);
      height: rem(80px);
      @extend .ttu;
      position: absolute;
      left: rem(-55px);
      top: rem(-10px);
      font-size: rem(18px);
      line-height: rem(23px);
      color: cl(white);
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: rem(5px 5px 5px 5px);
      letter-spacing: 0.3em;
      span{
        font-size: rem(40px);
        letter-spacing: 0;
        line-height: 1;
      }
    }
    img{
      margin-top: rem(20px);
      width: 100%;
      max-height: rem(220px);
      object-fit: contain;
      object-position: center top;
    }
  }


}
.home--academies-sollegiate-section{
  padding: rem(60px 0 65px 0);
  position: relative;
  @include media-breakpoint-down(sm) {
    padding: rem(60px 0 60px 0);
  }
  .block-deco-2{
    position: absolute;
    z-index: 0;
    right: rem(-470px);
    width: rem(646px);
    @extend .pos-centerY;
  }
  .block-deco-3{
    position: absolute;
    z-index: 1;
    width: rem(273px);
    top: 60%;
    right: 1rem;
  }
  .image-side{
    position: relative;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
    .img-bg{
      position: absolute;
      width: 53vw;
      top: 5vw;
      left: -6.4vw;
    }
    .image-holder{
      width: 89%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      color: cl(white);
      z-index: 3;
      border-radius: rem(50px 0px 50px 50px);
      @include media-breakpoint-down(lg) {
        width: 90%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .main-image{
        width: 100%;
        border-radius: rem(0px 50px 50px 50px);
        box-shadow: rem(-18.026px 30.001px 60px 0px rgba(0, 0, 0, 0.34));
        display: block;
        overflow: hidden;

      }
      .ttl-holder{
        position: absolute;
        z-index: 4;
        bottom: 0;
        padding-bottom: rem(18px);

        h2{
          margin-bottom: 0;
          color: cl(white);
          position: relative;
          z-index: 2;
        }
      }

    }
    .ttl-holder{
      margin-top: rem(50px);
      width: 89.69%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 3;
      @include media-breakpoint-down(md) {
        display: none;
      }
      h2{
        position: relative;
      }
      .news__leaf {
        position: absolute;
        width:rem(247px);
        height: auto;
       top: 0;
        left:rem(-267px);
        z-index: 2;
        @include media-breakpoint-down(xs) {
          width:5rem;
        }
      }
    }
  }
  .text-side{
    // max-width: rem(480px);
    font-size: rem(18px);
    line-height: 1.5;
    color:#333333;
    position: relative;
    z-index: 2;
    p{
      margin-bottom: 2rem;
    }
    .btns-wrap{
      margin-top: rem(40px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap:rem(18px);
      @include media-breakpoint-down(sm) {
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .btn {
        @include media-breakpoint-down(sm) {
          width: 48%;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
    }
    ul{
      list-style: none;
      li{
        padding: rem(5px 0 5px 28px);
        position: relative;
        &:before{
          content: "";
          left: 0;
          border-radius: 50%;
          background-color: cl(primary);
          position: absolute;
          width: 0.5rem;
          top: rem(15px);
          height: 0.5rem;
        }
      }
    }
  }
  .block-wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .big-side{
      width: 63%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .small-side{
      width: 37%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
.future-album-section{
  padding: rem(65px 0 40px 0);
  position: relative;
  z-index: 4;
  .bg-img{
   background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: -22.779px 29.156px 59px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    top:rem(65px);
    width: 48.5vw;
    height: calc(100% - 1.5rem);
    border-radius: rem(0px 0px 100px 0px);

    z-index: 1;
    @include media-breakpoint-down(lg) {
      width: calc(63% - 3rem);
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      height: 30rem;
      border-radius: rem(0px);
    }
  }
  .block-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .big-side{
      width: 63%;
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 5rem;
      }
    }
    .small-side{
      width: 37%;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
  .album-side{
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap:1.25rem 1.25rem;
    max-width: 25.52vw;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 2rem;
    }
    .album-link{
      background: right center/ cover no-repeat , cl(primary);
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: rem(20px 23px);
      font-size: rem(18px);
      font-weight: 400;
      line-height: 1.5;
      color: rgb(255, 255, 255);
      .ttl{
        font-size: rem(40px);
        color: cl(white);
        @extend .ttu;
        line-height: 1;
        font-family: $fontTitle;
        margin-bottom: 0.75rem;
        @include media-breakpoint-down(lg) {
          font-size: rem(35px);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(25px);
        }
      }
    }
    .item{
      border-radius: rem(50px);
      box-shadow: rem(-22.779px 29.156px 59px 0px rgba(0, 0, 0, 0.15));
      width: calc(50% - 0.75rem);
      padding-bottom: 47%;
      position: relative;
      overflow: hidden;
      //@include media-breakpoint-down(md) {
      //padding-bottom: 78%;
      //}
      &:first-child{
        border-radius: rem(0px 50px 50px 50px);
      }
      &:nth-child(2){
        border-radius: rem(50px 0px 50px 50px);
      }
      &:nth-child(3){
        border-radius: rem(50px 50px 50px 0px);
      }
      &:nth-child(4){
        border-radius: rem(50px 50px 0px 50px);
      }
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

  }
  .slider-side{
    position: relative;
    z-index: 5;
    max-width: rem(780px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
      padding: rem(80px 0 80px 0);
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    h2{
      color: cl(white);
      margin-bottom: 0;
    }
    .leaf-deco{
      position: absolute;
      right: rem(-120px);
      width: rem(120px);
      bottom: 1rem;
      @include media-breakpoint-down(lg) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .home-leaders-slider{
      margin-left: rem(-20px);
      padding-bottom: 3rem;
      padding-right: rem(80px);
      @include media-breakpoint-down(md) {
        padding-right: 0;
        margin-left: 0;
      }
      .slick-prev,
      .slick-next{
        position: absolute;
        border: 1px solid cl(white);
        background-color: cl(white);
        font-size: rem(25px);
        width: rem(50px);
        height: rem(50px);
        border-radius: 50%;
        cursor: pointer;
        color: cl(secondary);
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: rem(85px);
        box-shadow: 0px 9px 16px 0px rgba(0, 0, 0, 0.21);
        @include media-breakpoint-down(sm) {
          width: rem(30px);
          height: rem(30px);
          font-size: rem(15px);
        }
        @include media-breakpoint-down(xs) {
          right: rem(45px);
        }
        &:focus{
          outline: none;
        }
        &:hover{
          background-color: cl(white);
          color: cl(secondary);
          border: 1px solid cl(secondary);
        }
      }
      .slick-next{
        right: 1rem;
        @include media-breakpoint-down(xs) {
          right: 0;
        }
      }
      .item{
        padding: 2rem 1rem 2rem 1.5rem;
        .item-inner{
          display: block;
          padding: 1.25rem 0.75rem;
          border-radius:rem(30px);
          background-color: rgb(255, 255, 255);
          box-shadow: rem(-8px 12px 20px 0px rgba(0, 0, 0, 0.1));
          img{
            height: rem(107px);
            width: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
  .marque-wrap{
    margin-top: -5vw;
    margin-bottom: -4.3vw;
    overflow: hidden;
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10vw;
        color: cl(primary);
        line-height: 15vw;
        font-family: $fontTitle;
        text-transform: uppercase;
        // line-height:1;
        text-align: center;

        position: relative;
        span {
          background: url(../img/svg/SomersetOhio_Logoleafe-left.svg) no-repeat 20% 0%;
          background-size: 60%;
        }
        //&:after{
        //  content: "";
        //  position: absolute;
        //  @extend .pos-centerY;
        //  right: 1.5vw;
        //  width: 3vw;
        //  height: 3vw;
        //  z-index: 2;
        //  background-image: url("../img/deco.png");
        //  background-repeat: no-repeat;
        //  background-position: center;
        //  background-size: contain;
        //}
      }
    }
  }
  .bulb-bg{
    width: 133%;
    top: 0;
    left: -14%;
    position: absolute;
    @include media-breakpoint-down(md) {
     display: none;
    }
  }
  .leaf{
    width: 50%;
    position: absolute;
    top: 44%;
    left: 82%;
    transform: rotate(-73deg);
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

}


.best-teacher-section{
  padding: rem(80px 0 0px 0);
  background-size: 100% auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  @include media-breakpoint-down(md) {
    padding: rem(20px 0 0px 0);
  }
  .teachers-block{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding-right: 9vw;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }
      @include media-breakpoint-down(md) {

      flex-wrap: wrap;
      gap: 3rem;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;

    }
    .img-deco-3{
      position: absolute;
      width: rem(365px);
      left: 0;
      top: -2rem;
      @include media-breakpoint-down(md) {
       top: 50%;
        transform: translateY(-50%);
      }
    }
    .leaf{
      position: absolute;
      width: rem(345px);
      left: 45%;
      top: 11rem;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .decor {
      position: absolute;
      left: 79%;
      top: -10%;
      @include media-breakpoint-down(lg) {
        left: unset;
        right: 0;
        top:1rem;
      }
      &--top-right {
        span {
          &:nth-child(2) {
            top:rem(-5px);
            right:0;
          }
        }
      }
      &--bottom-left {
        span {
          &:nth-child(2) {
            bottom:rem(10px);
            left:rem(11px);
          }
        }
      }
      span {
        display: block;
        &:nth-child(1) {
          @include circle(113px);
          background: linear-gradient(235deg, #daeff7 0%,rgba(255,255,255,1) 100%);
        }
        &:nth-child(2) {
          position: absolute;
          @include circle(40px);
          background: linear-gradient(235deg, rgba(189,227,242,0.75) 0%, rgba(189,227,242,0.75) 80%);
          opacity: .7;
        }
      }
    }
    .teacher-ttl{
      margin-top: rem(60px);
      padding: rem(77px 20px 50px 0);
      font-size: rem(40px);
      line-height: rem(40px);
      font-weight: 700;
      color: #c0372f;
      font-family: $fontTitle;
      @extend .ttu;
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
        order: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top:0;
      }

      span{
        z-index: 3;
        position: relative;
        display: inline-block;
      }
      .logo{
        position: absolute;
        right: 0;
        width: 12vw;
        opacity: 0.18;
        @extend .pos-centerY;
        @include media-breakpoint-down(md) {
          left: 60%;
        }
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      .stars-top{
        position: absolute;
        left: 0;
        width: auto;
        height: rem(70px);
        top:0;
        @include media-breakpoint-down(md) {
          left: 45%;
          transform: translateX(-50%);
        }
      }
      .stars-bottom{
        position: absolute;
        left: 0;
        width: auto;
        height: rem(50px);
        bottom:0;
        @include media-breakpoint-down(md) {
          left: 45%;
          transform: translateX(-50%);
        }
      }
    }
    .teacher-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      @include media-breakpoint-down(md) {
        order: 1;
      }
      @include media-breakpoint-down(xs) {
        width: 90%;
        margin: 0 auto;
      }
      @media (max-width: 375.98px){
        width: 100%;
      }
      &:first-child{
        .item-inner{
          .name-wrap{
            background-color: #c0372f;
            &:before {
              border-top: 2.375rem solid #c0372f;
              border-bottom: 2.375rem solid #c0372f;
            }
            &:after {
              border-top: 2.375rem solid #c0372f;
              border-bottom: 2.375rem solid #c0372f;
            }
          }
        }
      }
      &:after{
        content: "";
        position: absolute;
        bottom: rem(-100px);
        width: rem(650px);
        height: rem(317px);
        z-index: 0;
        @extend .pos-centerX;
        background-image: url("../img/cloud.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .sub-ttl{
        font-size: rem(20px);
        font-weight: 700;
        color: #0a264a;
        z-index: 2;
        position: relative;
      }
      .item-inner{
        padding: rem(22px);
        border-style: solid;
        border:0;
        background-color: rgb(255, 255, 255);
        box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
        width: rem(330px);
        margin-bottom: rem(40px);
        border-radius: rem(20px);
        z-index: 2;
        position: relative;
        @include media-breakpoint-down(sm) {
          border-width: rem(6px);
          padding: rem(15px);
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
          border-width: rem(6px);
          padding: rem(15px);
        }

        .avatar{
          width: 100%;
          padding-bottom: 88%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: rem(16px);
        }
        .name-wrap{
          position: relative;
          font-size: rem(13px);
          font-weight: 400;
          text-align: center;
          color: cl(white);
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          background-color: cl(primary);
          height: rem(76px);
          width: calc(100% - 1.7rem);
          margin: 0 auto -2.5rem;
          padding: rem(3px 18px);
          &:before {
            position: absolute;
            top: 0;
            left:-0.84rem;
            display: block;
            border-left: 0.875rem solid transparent;
            border-top: 2.4rem solid cl(primary);
            border-bottom: 2.38rem solid cl(primary);
            width: 0;
            height: 0;
            content: " ";
          }
          &:after {
            position: absolute;
            top: 0;
            right: -0.84rem;
            display: block;
            border-right: 0.875rem solid transparent;
            border-top: 2.4rem solid cl(primary);
            border-bottom: 2.38rem solid cl(primary);
            width: 0;
            height: 0;
            content: " ";
          }
        }

        .name{
          font-size: rem(20px);
          font-weight: 700;
          position: relative;
          margin-bottom: 0.5rem;
        }
      }

    }
  }
  .marque-wrap{
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
    }
    .marquee-honor{
      b{
        padding-right: 10vw;
        font-size: 10.4vw;
        color: rgba(255, 255, 255, 0.8);
        line-height: 11vw;
        font-family: $fontTitle;
        text-transform: uppercase;
        // line-height:1;
        text-align: center;
        font-weight: 400;
        position: relative;
        -webkit-text-stroke: 1px #0a264a;
        text-stroke: 1px #0a264a;
        @include media-breakpoint-down(sm) {
          font-size: 15.4vw;
          line-height: 15.6vw;
        }
        span {
          display: inline-block;
          position: relative;
          .img{
            width: 110%;
            position: absolute;
            right: -50%;
            bottom: 22%;
          }
        }
        //&:after{
        //  content: "";
        //  position: absolute;
        //  @extend .pos-centerY;
        //  right: 1.5vw;
        //  width: 3vw;
        //  height: 3vw;
        //  z-index: 2;
        //  background-image: url("../img/deco.png");
        //  background-repeat: no-repeat;
        //  background-position: center;
        //  background-size: contain;
        //}
      }
    }
  }
}





.float-more-btns{
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include media-breakpoint-down(md) {
    display: none;

  }
  a{
    background-color: cl(secondary);
    color: cl(white);
    @extend .ttu;
    padding:rem(10px);
    font-size: rem(16px);
    font-weight: 700;
    margin-bottom: 0.25rem;
    text-align: center;
    min-width: rem(142px);
    box-shadow: -1.22px 13.947px 32px 0px rgba(0, 0, 0, 0.21);
    &:hover{
      background-color: cl(primary);
      text-decoration: none;
    }
    &:first-child{
      min-width: rem(153px);
    }
  }
}